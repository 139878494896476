import React from "react"
import { navigate } from "gatsby"
import { BtnWrapper, Button } from "../style/common/common"

const ReadMore = (props) => {
  const to = props.to
  const label = props.label

  return (
    <BtnWrapper>
      <Button onClick={() => navigate(to)}>
        {label}
      </Button>
    </BtnWrapper>
  )
}

export default ReadMore
