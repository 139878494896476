import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RelatedTitle, WidgetNewsRelated, RelatedNewsBody, RelatedNewsImg, RelatedNewsTxt, RelatedNewsTtl, RelatedNewsMeta, NewsDate, RelatedNewsCat } from '../style/common/common'

const NewsRelated = props => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY.MM.DD")
              title
              author
              category
              thumb {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
          }
        }
      }
    }
  `)

  // let yy
  let newsRelatedModule
  if (props.relatedURL) {
    const relatedNewsURL = props.relatedURL
      .map(el => data.allMarkdownRemark.edges
        .filter(edge => edge.node.fields.slug === `/${el}/`)
        .map(edge => {
          const ttl = edge.node.frontmatter.title
          const url = edge.node.fields.slug
          const category = edge.node.frontmatter.category
          const date = edge.node.frontmatter.date
          // const thumb = edge.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
          const thumb = getImage(edge.node.frontmatter.thumb[0])

          return (
            <article>
              <Link to={`/news${url}`}>
                <RelatedNewsImg>
                  <GatsbyImage
                    image={thumb}
                    alt={ttl}
                  />
                </RelatedNewsImg>
                <RelatedNewsTxt>
                  <RelatedNewsTtl>
                    {ttl}
                  </RelatedNewsTtl>
                  <RelatedNewsMeta>
                    <NewsDate>{date}</NewsDate>
                    <RelatedNewsCat>{category}</RelatedNewsCat>
                  </RelatedNewsMeta>
                </RelatedNewsTxt>
              </Link>
            </article>
          )
        }
      )
    )

    newsRelatedModule = (
      <WidgetNewsRelated>
        <RelatedTitle>関連記事</RelatedTitle>
        <RelatedNewsBody>
          {relatedNewsURL}
        </RelatedNewsBody>
      </WidgetNewsRelated>
    )
  }

  return (
    <>{newsRelatedModule}</>
  )
}

export default NewsRelated
