import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RelatedTitle, RelatedBooksWrapper, RelatedBooksBody, RelatedBookImg, RelatedBookTxt, RelatedBookTtl, RelatedBookAut, RelatedBookCat } from '../style/common/common'

const BooksRelated = props => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark{
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              author
              category
              pubDate
              thumb {
                childImageSharp {
                  gatsbyImageData(width: 100)
                }
              }
            }
          }
        }
      }
    }
  `)

  const basePosts = data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.category === props.cat && edge.node.id !== props.id).map(edge => {
    const bookTitle = edge.node.frontmatter.title
    const bookAuthor = edge.node.frontmatter.author
    const bookCategory = edge.node.frontmatter.category
    // const bookImg = edge.node.frontmatter.featuredImage
    const thumb = getImage(edge.node.frontmatter.thumb[0])

      return (
        <article key={edge.node.id}>
          <Link to={`/books${edge.node.fields.slug}`}>
            <RelatedBookImg>
              <GatsbyImage
                image={thumb}
                alt={bookTitle}
              />
            </RelatedBookImg>
            <RelatedBookTxt>
              <RelatedBookTtl>{bookTitle}</RelatedBookTtl>
              <RelatedBookAut>{bookAuthor}</RelatedBookAut>
              <RelatedBookCat>{bookCategory}</RelatedBookCat>
            </RelatedBookTxt>
          </Link>
        </article>
      )
    }
  )

  let booksRelatedModule
  if (basePosts.length >= 1) {
    booksRelatedModule = (
      <RelatedBooksWrapper>
        <RelatedTitle>関連書籍</RelatedTitle>
        <RelatedBooksBody>
          {basePosts}
        </RelatedBooksBody>
      </RelatedBooksWrapper>
    )
  }

  return (
    <>{booksRelatedModule}</>
  )
}

export default BooksRelated
