//blog-post-temp.js
import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import SEO from '../components/seo'
import Layout from "../components/layout"
import BooksRelated from "../components/booksRelated"
import NewsRelated from "../components/newsRelated"
import Video from "../components/video"
import SimpleSlider from "../components/slick"
import ShopBtnGroup from "../components/shopBtnGroup"
import { LeftWrapper, RightWrapper, BookWrapper, BookPostHeader, BookPostTtl, BookPostAut, BookPostMeta, BtnTwitterShare, LabelEbooks } from "../style/common/common"
import { MdBody } from "../style/common/markdown"
import ReadMore from "../components/btnReadMore"
import _ from "lodash"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const BookPost = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const currentPath = data.site.siteMetadata.siteUrl + location.pathname
  const { breadcrumb: { crumbs } } = pageContext
  let videoWrapper
  let staffWrapper
  let relatedNewsWrapper
  let pubDates
  let ebooks

  const ogpImg = getImage(post.frontmatter.thumb[0])
  const ogpSrc = ogpImg.images.fallback.src

  if (post.frontmatter.videos) {
    videoWrapper = post.frontmatter.videos.map((video, index) => {
      return (
        <Video
          key={index}
          videoSrcURL={video.srcURL}
          videoTitle={video.srcTitle}
        />
      )
    })
  }

  if (post.frontmatter.staff) {
    staffWrapper = post.frontmatter.staff.map((staff, index) => {
      return (
        <React.Fragment key={index}>
          <dt>{staff.staffRole}</dt>
          <dd>{staff.staffName}</dd>
        </React.Fragment>
      )
    })
  }

  if (post.frontmatter.relatedNewsURL.length) {
    relatedNewsWrapper = <NewsRelated relatedURL={post.frontmatter.relatedNewsURL} />
  } else {
    relatedNewsWrapper = <></>
  }

  if (post.frontmatter.pubDate && post.frontmatter.pubDate.length > 1) {
    pubDates = post.frontmatter.pubDate.map((el, index) => {
      let dt
      if (index === 0) {
        dt = (<dt>発行日</dt>)
      } else {
        dt = (<dt>{index + 1}刷</dt>)
      }
      return (
        <React.Fragment key={index}>
          {dt}
          <dd>{el}</dd>
        </React.Fragment>
      )
    })
  } else {
    pubDates = post.frontmatter.pubDate.map((el, index) => {
      return (
        <React.Fragment key={index}>
          <dt>発行日</dt>
          <dd>{el}</dd>
        </React.Fragment>
      )
    })
  }
  ebooks = post.frontmatter.ebooks ? <LabelEbooks>電子版あり</LabelEbooks> : <></>

  return (
    <Layout
      location={location}
    >
      <SEO
        title={`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}
        image={ogpSrc}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        crumbLabel={post.frontmatter.title}
      />
      <BookWrapper>
        <LeftWrapper>
          <SimpleSlider images={post.frontmatter.thumb} alt={post.frontmatter.title}/>
          <ShopBtnGroup url={post.frontmatter}/>
        </LeftWrapper>
        <RightWrapper>
          <BookPostHeader>
            <BookPostAut className="book__aut">
              {post.frontmatter.author}
            </BookPostAut>
            <BookPostTtl>
              <h1>{post.frontmatter.title}</h1>
              {ebooks}
            </BookPostTtl>
            <BookPostMeta>
              <dt>カテゴリ</dt>
              <dd>{post.frontmatter.category}</dd>
              <dt>定価</dt>
              <dd>{post.frontmatter.price}</dd>
              <dt>判型</dt>
              <dd>{post.frontmatter.size}</dd>
              <dt>製本</dt>
              <dd>{post.frontmatter.binding}</dd>
              <dt>頁数</dt>
              <dd>{post.frontmatter.page}</dd>
              <dt>ISBN</dt>
              <dd>{post.frontmatter.isbn}</dd>
              {staffWrapper}
              {pubDates}
            </BookPostMeta>
            <BtnTwitterShare>
              <a href={`https://twitter.com/intent/tweet?url=${currentPath}&text=${post.frontmatter.title}&hashtags=素粒社`} target="_blank" rel="noopener noreferrer">
                <span><img src={`../../icons/icon-twitter.svg`} alt="twitter"/></span>ツイート
              </a>
            </BtnTwitterShare>
          </BookPostHeader>
          <MdBody dangerouslySetInnerHTML={{ __html: post.html }}/>
        </RightWrapper>
      </BookWrapper>
      {videoWrapper}
      {relatedNewsWrapper}
      <BooksRelated id={post.id} cat={post.frontmatter.category}/>
      <ReadMore to="/books/" label="もどる"/>
    </Layout>
  )
}
export default BookPost

export const query = graphql`
  query BookPostQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
    ) {
      html
      id
      frontmatter {
        title
        author
        date(formatString: "YYYY.MM.DD")
        category
        price
        size
        binding
        page
        isbn
        staff {
          staffRole
          staffName
        }
        pubDate
        storesURL
        rakutenURL
        amazonURL
        hanmotoURL
        thumb {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
        videos {
          srcURL
          srcTitle
        }
        relatedNewsURL
        ebooks
      }
    }
  }

`
