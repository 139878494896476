import React, { useState } from 'react'
import MediaQuery from 'react-responsive'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from "react-slick";
import Lightbox from "react-image-lightbox"
import { SlideWrapper } from '../style/common/common'
import "react-image-lightbox/style.css"

const SimpleSlider = ({ images, alt }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  }

  const handleClickItem = (index) => {
    setIsOpen(true)
    setPhotoIndex(index)
  }

  const gallery = images.map((image, index) => (
    <div key={index} onClick={() => handleClickItem(index)}>
      <GatsbyImage image={getImage(image)} alt={alt} />
    </div>
  ))

  return (
    <SlideWrapper>
      <Slider {...settings}>
        {gallery}
      </Slider>
      <MediaQuery minDeviceWidth={768}>
        {isOpen && (
          <Lightbox
            mainSrc={getImage(images[photoIndex]).images.fallback.src}
            nextSrc={getImage(images[(photoIndex + 1) % gallery.length])}
            prevSrc={getImage(images[(photoIndex + gallery.length - 1) % gallery.length])}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + gallery.length - 1) % gallery.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % gallery.length)
            }
            enableZoom={false}
            clickOutsideToClose={true}
            imagePadding={100}
          />
        )}
      </MediaQuery>
    </SlideWrapper>
  )
}

export default SimpleSlider
