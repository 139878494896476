import React from "react"
import { ShopBtnWrapper } from "../../src/style/common/common"
// import amazon from '../../icon-amazon.svg'
// import rakuten from '../../icon-rakuten.svg'
// import hanmoto from '../../icon-hanmoto.svg'

const ShopBtnGroup = (props) => {
  let Buttons

  if (props.url.storesURL || props.url.amazonURL || props.url.rakutenURL || props.url.hanmotoURL) {
    Buttons = (
      <ShopBtnWrapper>
        {props.url.storesURL && <a href={props.url.storesURL} target="_blank" rel="noopener noreferrer">素粒社ウェブショップ</a>}
        {props.url.amazonURL && <a href={props.url.amazonURL} target="_blank" rel="noopener noreferrer"><img className="icon-amazon" src={`../../icons/icon-amazon.svg`} alt="amazon"/></a>}
        {props.url.rakutenURL && <a href={props.url.rakutenURL} target="_blank" rel="noopener noreferrer"><img className="icon-rakuten" src={`../../icons/icon-rakuten.svg`} alt="楽天"/></a>}
        {props.url.hanmotoURL && <a href={props.url.hanmotoURL} target="_blank" rel="noopener noreferrer"><img className="icon-hanmoto" src={`../../icons/icon-hanmoto.svg`} alt="版元ドットコム"/></a>}
      </ShopBtnWrapper>
    )
  }

  return (
    <>{Buttons}</>
    // <ShopBtnWrapper>
    //   {props.url.storesURL && <Link to={props.url.storesURL} target="_blank" rel="noopener noreferrer">素粒社ウェブショップ</Link>}
    //   {props.url.amazonURL && <Link to={props.url.amazonURL} target="_blank" rel="noopener noreferrer"><img className="icon-amazon" src={amazon}/></Link>}
    //   {props.url.rakutenURL && <Link to={props.url.rakutenURL} target="_blank" rel="noopener noreferrer"><img className="icon-rakuten" src={rakuten}/></Link>}
    //   {props.url.hanmotoURL && <Link to={props.url.hanmotoURL} target="_blank" rel="noopener noreferrer"><img className="icon-hanmoto" src={hanmoto}/></Link>}
    // </ShopBtnWrapper>
  )
}

export default ShopBtnGroup
