import styled from "styled-components"
import Device from "../responsive/device"

export const txtCol = "#333"
export const keyCol = "#1d2088"
export const subCol = "#f0f1fc"
export const linkCol = txtCol

export const MdBody = styled.div`
  > :last-child {
    margin-bottom: 0;
  }

  .gatsby-code-title {
    background: #2e96b5;
    color: #eee;
    padding: 6px 12px;
    font-size: 0.8em;
    line-height: 1;
    font-weight: bold;
    display: table;
    border-radius: 4px 4px 0 0;
  }

  .gatsby-code-title span {
    display: inline;
    position: relative;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    color: #fff;
    background: rgba(52,152,219,0.2);
    border-bottom-right-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
    padding: 3px;
    top: 1px;
  }

  h2 {
    font-size: 1.8rem;
    border-left: 8px solid ${keyCol};
    padding: 1px 15px;
    margin: 40px 0 -18px 0;
    letter-spacing: 0.15em;
    color: ${keyCol};

    + h3 {
      margin-top: 45px;
    }

    + p {
      margin-top: 36px;
    }

    @media ${Device.tablet} {
      margin-top: 80px;
    }
  }

  h3 {
    font-size: 1.6rem;
    border-top: 2px solid ${keyCol};
    border-bottom: 2px solid ${keyCol};
    padding: 5px 0 5px 10px;
    margin: 36px 0 -18px 0;
    letter-spacing: 0.15em;

    + h4,
    + p {
      margin-top: 36px;
    }
    + table {
      margin-top: 45px;
    }
  }

  h4 {
    position: relative;
    font-size: 1.5rem;
    padding-left: 28px;
    margin: 18px 0 -18px 0;
    letter-spacing: 0.15em;

    &::before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      top: 3px;
      left: 0;
      background-color: ${keyCol};
    }

    &::after {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      top: 10px;
      left: 7px;
      background-color: white;
    }

    + h3 {
      margin-top: 54px;
    }

    + p {
      margin-top: 36px;
    }

    + ul,
    + ol {
      margin-top: 36px;
    }
  }

  p {
    line-height: 1.8;
    margin: 18px 0;
    font-size: 1.4rem;

    img {
      max-width: 100%;
      height: auto;
    }

    + table {
      margin-top: 0;
    }

    + ul {
      margin-top: -9px;
    }

    + ol {
      margin-top: -9px;
    }
  }

  a {
    color: ${keyCol};
    text-decoration: underline;
  }

  table {
    display: block;
    width: max-content;
    max-width: 100%;
    overflow: auto;
    margin: 36px 0;
    border-collapse: collapse;

    th, td {
      font-size: 1.4rem;
      padding: 6px 13px;
      border:1px solid grey;
    }
  }

  blockquote {
    padding: 0 20px;
    margin: 36px 0;
    border-left: 5px solid #eee;
  }

  ul,
  ol {
    padding-left: 40px;
    margin: 36px 0;

    li {
      line-height: 1.8;
      font-size: 1.4rem;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }

    + ul,
    + ol {
      margin-top: -18px;
    }

    + p {
      margin-top: -18px;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }

    + ul,
    + ol {
      margin-top: -18px;
    }

    + p {
      margin-top: -18px;
    }
  }

  pre {
    margin: 0;
  }

  h3 {
    + pre {
      margin-top: 36px;
    }
  }

  sup {
    top: -0.5em;

    a {
      color: red;
      text-decoration: none;
    }
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  iframe {
    margin-top: 36px;
  }
`
