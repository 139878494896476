import React from "react"
import { VideoWrapper, VideoCaption } from "../style/common/common"

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
  const id = videoSrcURL.split('youtu.be/')[1];
  const embedURL = `https://www.youtube.com/embed/${id}`

  return (
    <VideoWrapper>
      <div className="video">
        <iframe
          src={embedURL}
          title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
      <VideoCaption>{videoTitle}</VideoCaption>
    </VideoWrapper>
  )
}
export default Video
